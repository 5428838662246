@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  margin: 0;
  font-family: Roboto;
  color: rgb(50, 50, 50);
}

td {
  padding-left: 1%;
}

h3 {
  font-size: 15px;
}

ul {
  list-style-type: none;
}

/* --------------------------- */
/* --- HEADER & FOOTER SECTION */
/* --------------------------- */
.nav {
  width: 100%;
  background: #f5f5f5;
}

.header {
  height: 75px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.header-menu {
  height: 50px;
  width: 50px;
  margin: auto 15px;;
}

.header-accounts {
  width: 250px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.header-accountbutton {
  display: block;
  text-decoration: none;
  text-align: center;
  background-color: #C000FF;
  color: white;
  margin: auto 2%;
}

.header-linktobutton {
  padding-bottom: 5px;
  height: 15px;
}

.header-buttontolink {
  padding-top: 3px;
  max-height: 22px;

}

.header-accountbutton:hover {
  background-color: #6A1D87;
}

.header-link {
  display: block;
  width: 70px;
  margin: auto 1%;
  text-decoration: none;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}

/* --- Navigation Sidebar Menu*/
.navbar {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 2%;
  font-size: 24px;
  font-weight: 700;
  color: white;
  background: none;
  text-decoration: none;
}

.nav-menu {
  background-color: #2AF48F;
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2%;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1%;
  border-radius: 4px;
}

nav-menu-items {
  width: 100%;
  padding: 0;
  margin-top: 20px;
}

.nav-offmenu {
  background-color: yellow;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.footer {
  background: #f5f5f5;

}

.footer-linkbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 2%;
  justify-content: center;
}

.sub-link {
  width: 150px;
}

/* --------------------------- */
/* --- GENERAL STYLING ------- */
/* --------------------------- */

.grid-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.general-header {
  margin: 2% 0.5% 0 0.5%;
  height: 25px;
}

.general-heading {
  margin-left: 10px;
  font-size: 18px;
}

.general-button {
  height: 25px;
  width: 75px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.width-button {
  width: 125px;

}

.general-link {
  text-decoration: none;
  text-align: center;
  margin: auto 2%;
}

.general-greeting {
  text-decoration: none;
  text-align: center;
  margin: auto 2%;
  width: 150px;
}

.general-removebutton {
  position: relative;
  background-color: #FF395A;
  color: white;
  top: -20px;
  right: 5px;
  float: right;
}

.general-removebutton:hover {
  background-color: #A31A38;
}

.general-flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.general-linkbox {
  width: 17%;
  height: 100px;
  text-decoration: none;
  background: #0092FF;
  margin: 20px;
}

.general-create {
  background: #fca326;
}

.general-linkbox:hover {
  filter: drop-shadow(5px 5px 2px #6fbdf7);
}

.general-homelink {
  display: block;
  margin: 40px 0;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 900;
}

.modal {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.modal-content {
  background: white;
  position: absolute;
  top: 5%;
  right: 2%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
}

.modal-close {
  float: right;
}

.general-error {
  margin: 0.25% auto;
  color: red;
}

/* --------------------------- */
/* ----- LOGIN SECTION ------- */
/* --------------------------- */

.login-container {
  width: 375px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;


  grid-column: 1 / 5;
  grid-row: 1;
}

.login-image {
  display: none;
}

.login-box {
  width: 70%;
  margin: 5%;
  padding: 4%;
}

.login-heading {
  text-align: center;
  padding-bottom: 4%;
}

.login-input {
  height: 27px;
  width: 225px;
  display: block;
  margin: 4% auto;
  border: 2px solid #e3e3e3;
  background-color: #f7f7f7;
}

.login-department {
  height: 27px;
  width: 233px;
  display: block;
  margin: 4% auto;
  border: 2px solid #e3e3e3;
  background-color: #f7f7f7;
}

.login-button {
  height: 27px;
  width: 225px;
  display: block;
  margin: 5% auto;
  text-align: center;
  color: white;
}

.login-submit {
  background-color: #0092FF;
}

.login-submit:hover {
  background: #6fbdf7;
}

.login-forgot {
  background-color: #FF395A;
}

.login-forgot:hover {
  background-color: #A31A38;
}

.login-orbox {
  width: 85%;
  height: 25px;
  margin: auto;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.login-line {
  height: 10px;
  width: 90px;
  border-bottom: 2px solid #8c8c8c;
}

.login-ortext {
  margin: 0 4%;
  color: #8c8c8c
}

.login-error {
  display: block;
  margin: 1% auto;
  width: 90%;
  padding: 1%;
  color: red;
}

/* --------------------------- */
/* --- INFRACTION SECTION ---- */
/* --------------------------- */
#home-content {
  background: #fafafa;
}

#home-top {
  grid-column: 1 / 5;
  grid-row: 1;
}

#infractions-top {
  grid-column: 1 / 5;
  grid-row: 1;
  justify-content: center;
}

#home-priviligeerror {
  grid-column: 1 / 5;
  grid-row: 2;
  margin: 0;
}

#home-boxview {
  grid-column: 1 / 5;
  grid-row: 3;
  justify-content: center;
}

.home-table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 12px;
  width: 100%;
  margin: 0 5px 20px 5px;
}

.home-tablenotes {
  max-width: 150px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.home-tableheading {
  font-weight:700;
}

.paginate-container {
  grid-column: 1 / 5;
  grid-row: 4;
}

.home-filterrow {
  display: block;
  text-align: right;
}

.home-filterbutton {
  position: relative;
  top: -20px;
  float: right;
  background-color: #2AF48F;
  margin-left: auto;
  width: 55px;
}

.home-filterbutton:hover {
  background-color: #48A072;
  color: white;
}

.home-data {
  width: 95%;
  padding: 1%;
  margin-left: 1%;
  font-size: 14px;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.home-data-headers {
  font-size: 16px;
}

.home-data-border {
  border-left: 5px solid #2b87ff;
}

.home-data-row {
  height: 35px;
  background: #f7f7f7;
}

.home-data-row:hover {
  background: #dbdbdb;
}

.home-data-notes {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home-updatebutton {
  display: block;
  text-decoration: none;
  text-align: center;
  background-color: #C000FF;
  color: white;
  padding-top: 5px;
  height: 20px;
}

.home-updatebutton:hover {
  background-color: #6A1D87;
}

.home-deletebutton {
  background-color: #FF395A;
  color: white;
}

.home-deletebutton:hover {
  background-color: #A31A38;
}

.paginate-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5%;
}

.paginate-page {
  font-weight: 700;
  padding: 5px;
}

.paginate-page:hover {
  font-weight: 900;
  font-size: 20px;
}

.paginate-previous {
  width: 55px;
  height: 25px;
  padding: 3px;
  font-weight: 900;
  font-size: 18px;

  background: #2AF48F;
  border-radius: 125px;
  color: black;
}
.paginate-previous:hover {
  background: #48A072;
  color: white;
}

.paginate-next {
  width: 55px;
  height: 25px;
  padding: 3px;
  font-weight: 900;
  font-size: 18px;
  background: #2AF48F;
  border-radius: 125px;
  color: black;
}

.paginate-next:hover {
  background: #48A072;
  color: white;
}

#infraction-additem {
  display: block;
  text-decoration: none;
  width: 100px;
  height: 25px;
  background: #2AF48F;
  color: black;
  margin: 2% auto;
  padding-top: 5px;
}

#infraction-additem:hover {
  background: #48A072;
  color: white;
}

#infraction-removeitem {
  display: block;
  text-decoration: none;
  width: 125px;
  height: 25px;
  background: #FF395A;
  color: white;
  margin: 2% auto;
  padding-top: 5px;
}

#infraction-removeitem:hover {
  background: #A31A38;
}

/* --------------------------- */
/* --- PROFILE SECTION ------- */
/* --------------------------- */

#profile {
  text-align: center;
}

#profile-heading {
  grid-column: 1 / 5;
  grid-row: 1;
}

#profile-info {
  grid-column: 1 / 5;
  grid-row: 2;
}

#profile-preferences {
  grid-column: 1 / 5;
  grid-row: 3;
}

#profile-submit {
  grid-column: 1 / 5;
  grid-row: 4;
}

.profile-box {
  padding: 3%;
}

.profile-updatebutton {
  display: block;
  width: 150px;
  background-color: #2AF48F;
  margin: 3% auto;
}

/* --------------------------- */
/* --- FORM SECTION ---------- */
/* --------------------------- */
#create-form {
  background: #fafafa;
}

#infraction-form {
  grid-column: 1 / 5;
  grid-row: 2
}

#infraction-submit {
  grid-column: 1 / 5;
  grid-row: 3;
}

#form-update {
  grid-column: 1 / 5;
  grid-row: 2;
}

.form-container {
  grid-column: 1 / 5;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form-instance {
  background: #f5f5f5;
  width: 85%;
  padding: 2% 3%;
  margin: 1% auto;
  text-align: center; 
}

.form-players {
  margin: 2px;
  background-color: #f5f5f5;
  border: 2px solid #e3e3e3;
}

.court {
  width: 40px;
  height: 40px;
  font-size: 24px;
}

.normal {
  width: 100px;
}

.large {
  width: 150px;
}

#notes {
  height: 100px;
  width: 300px;
}

.form-deletebutton {
  margin: 1%;
  background-color: #FF395A;
  color: white;
}

.form-deletebutton:hover {
  background-color: #A31A38;
}

.form-updatebutton {
  margin: 1%;
  background-color: #9986b0;
  color: white;
}

.form-updatebutton:hover {
  background-color: #c1b6cf;
}

.form-selectedbutton {
  margin: 1%;
  background-color: #7703fc;
  color: white;
}

.form-selectedbutton:hover {
  background-color: #8d5fc2;
}

/* --------------------------- */
/* ------ PO SECTION --------- */
/* --------------------------- */ 

.po-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

#po-top {
  grid-column: 1 / 5;
  grid-row: 1;
}

.poform-container {
  grid-column: 1 / 5;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* ------ PO FORM --------- */

#po-form {
  grid-column: 1 / 5;
  grid-row: 2;
  display: grid;
  grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
  font-size: 12px;
}

#poform-heading {
  grid-column: 1 / 6;
  grid-row: 1 / 2;
  text-align: left;
  font-size: 24px;
}

#error-privilige {
  grid-column: 1 / 6;
  grid-row: 2 / 3;
  text-align: left;
}

#po-submit {
  grid-column: 1 / 5;
  grid-row: 3;
}

#po-updatesubmit {
  grid-column: 1 / 5;
  grid-row: 3;
}

#label-date {
  grid-column: 7 / 8;
  grid-row: 1;
}

#poform-date {
  grid-column: 8 / 9;
  grid-row: 1;
}

#poform-dd {
  grid-column: 8 / 9;
  grid-row: 1;
}

#poform-yy {
  grid-column: 9 / 10;
  grid-row: 1;
}

#label-ponum {
  grid-column: 7 / 8;
  grid-row: 2;
}

#poform-ponum {
  grid-column: 8 / 9;
  grid-row: 2;
}

#error-gl {
  grid-column: 5 / 7;
  grid-row: 3;
}

#label-gl {
  grid-column: 7 / 8;
  grid-row: 3;
}

#poform-gl {
  grid-column: 8 / 9;
  grid-row: 3
}

#poform-payee {
  grid-column: 3 / 4;
  grid-row: 4;
  text-align: left;
  margin: 5px;
}

#error-pto {
  grid-column: 4 / 6;
  grid-row: 4;
  line-height: 35px;
}

#label-pto {
  grid-column: 1 / 3;
  grid-row: 5;
}

#poform-pto {
  grid-column: 3 / 6;
  grid-row: 5;
}

#label-paddress {
  grid-column: 1 / 3;
  grid-row: 6;
}

#poform-paddress {
  grid-column: 3 / 6;
  grid-row: 6;
}

#label-pcity {
  grid-column: 1 / 3;
  grid-row: 7;
}

#poform-pcity {
  grid-column: 3 / 6;
  grid-row: 7;
}

#label-ppostal {
  grid-column: 1 / 3;
  grid-row: 8;
}

#poform-ppostal {
  grid-column: 3 / 6;
  grid-row: 8;
}

#poform-supplier {
  grid-column: 7 / 8;
  grid-row: 4;
  text-align: left;
  margin: 5px;
}

#error-sto {
  grid-column: 8 / 10;
  grid-row: 4;
  line-height: 35px;
}

#poform-sto {
  grid-column: 7 / 10;
  grid-row: 5;
}

#poform-saddress {
  grid-column: 7 / 10;
  grid-row: 6;
}

#poform-scity {
  grid-column: 7 / 10;
  grid-row: 7;
}

#poform-spostal {
  grid-column: 7 / 10;
  grid-row: 8;
}

#poform-lineitems {
  grid-column: 1 / 10;
  grid-row: 9;
}

#label-subtotal {
  grid-column: 7 / 8;
  grid-row: 10;
}

#poform-subtotal {
  grid-column: 8 / 9;
  grid-row: 10;
}

#label-hst {
  grid-column: 7 / 8;
  grid-row: 11;
}

#poform-hst {
  grid-column: 8 / 9;
  grid-row: 11;
}

#label-total {
  grid-column: 7 / 8;
  grid-row: 12;
}

#poform-total {
  grid-column: 8 / 9;
  grid-row: 12;
}

#poform-additem {
  grid-column: 4 / 5;
  grid-row: 13;
}

#poform-removeitem {
  grid-column: 6 / 7;
  grid-row: 13;
}

.poform-label {
  display: inline-block;
  margin: 5px;
  text-align: left;
}

.poform-table {
  width: 100%;
  margin: 5px;
}

.poform-xsmall {
  width: 40%;
  text-align: center;
}

.poform-readonly {
  width: 40%;
  height: 20px;
  background: white;
  border: 1px solid black;
  border-radius: 2px;
  line-height: 20px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
}

.poform-large {
  width: 100%;
  text-align: center;
}

.poform-xlarge {
  width: 100%;
  text-align: center;
}

.poform-xxlarge {
  width: 100%;
  text-align: center;
}

#po-removeitem {
  display: inline-block;
  text-decoration: none;
  width: 15px;
  height: 20px;
  line-height: 15px;
  background: #FF395A;
  color: white;
  margin: 2% 3%;
  padding-top: 5px;
}

/* --------------------------- */
/* ------ PROGRAM SECTION --------- */
/* --------------------------- */ 

.program-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

#program-top {
  grid-column: 1 / 5;
  grid-row: 1;
}

.programform-container {
  grid-column: 1 / 5;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* ------ PROGRAM FORM --------- */

#program-form {
  grid-column: 1 / 5;
  grid-row: 2;
  display: grid;
  grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
  font-size: 12px;
}

#programform-heading {
  grid-column: 1 / 6;
  grid-row: 1 / 2;
  text-align: left;
  font-size: 24px;
}

#error-privilige {
  grid-column: 1 / 6;
  grid-row: 2 / 3;
  text-align: left;
}

#program-submit {
  grid-column: 1 / 5;
  grid-row: 3;
}

#program-updatesubmit {
  grid-column: 1 / 5;
  grid-row: 3;
}

#label-title {
  grid-column: 1 / 3;
  grid-row: 4;
}

#progform-title {
  grid-column: 3 / 6;
  grid-row: 4;
}

#label-description {
  grid-column: 1 / 3;
  grid-row: 5;
}

#progform-description {
  grid-column: 3 / 10;
  grid-row: 5;
}

#label-date {
  grid-column: 1 / 3;
  grid-row: 6;
}

#progform-date {
  grid-column: 3 / 6;
  grid-row: 6;
}

#label-participants {
  grid-column: 1 / 3;
  grid-row: 7;
}

#progform-participants {
  grid-column: 3 / 6;
  grid-row: 7;
}

#label-daysToRegister {
  grid-column: 1 / 3;
  grid-row: 8;
}

#progform-daysToRegister {
  grid-column: 3 / 6;
  grid-row: 8;
}

#label-email {
  grid-column: 1 / 3;
  grid-row: 9;
}

#progform-email {
  grid-column: 3 / 6;
  grid-row: 9;
}

.programform-label {
  display: inline-block;
  margin: 5px;
  text-align: left;
}

.progform-xlarge {
  width: 100%;
  height: 200px;
  text-align: center;
}

@media only screen and (min-width: 840px)
{
  #home-infractiontable {
    display:table;
  }
}


@media only screen and (min-width: 1024px)
{ 

  .general-linkbox {
    width: 18%;
  }

  .general-homelink {
    font-size: 24px;
  }
  /* --------------------------- */
  /* --- LOGIN SECTION --------- */
  /* --------------------------- */ 

  .login-container {
    width: 100%;
  }

  .login-image {
    display: block;

    width: 30%;
    height: auto;
    margin: 5%;
  }

  .login-box {
    width: 30%;
    margin: 5%;
    padding: 4%;
  }

  .login-heading {
    text-align: center;
    padding-bottom: 4%;
    font-size: 28px;
  }

  .login-input {
    width: 275px;
  }

  .login-department {
    width: 283px;
  }

  .login-button {
    width: 275px;
  }

  .login-orbox {
    width: 275px;
    height: 25px;
    margin: auto;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .login-line {
    height: 10px;
    width: 115px;
    border-bottom: 2px solid #8c8c8c;
  }

  .login-ortext {
    margin: 0 4%;
    color: #8c8c8c
  }

  /* --------------------------- */
  /* --- PROFILE SECTION ------- */
  /* --------------------------- */ 
  .profile-updatebutton {
    display: block;
    width: 150px;
    background-color: #2AF48F;
    margin: 2% auto;
  }

  /* --------------------------- */
  /* --- INFRACTION SECTION ---- */
  /* --------------------------- */ 
  #home-infractiontable {
    display:table;
  }

  .home-updatebutton {
    font-size: 14px;
    height: 20px;
    width: 85px;
  }

  .home-deletebutton {
    font-size: 14px;
    height: 25px;
    width: 85px;
  }

  .modal-content{
    top: 8%;
  }

  /* --------------------------- */
  /* --- FORM SECTION ---------- */
  /* --------------------------- */ 
  
  .form-instance {
    width: 850px;
  }
  
  /* --------------------------- */
  /* ------ PO SECTION --------- */
  /* --------------------------- */ 
  .poform-label {
    display: inline-block;
    margin: 5px;
  }

  .poform-table {
    width: 100%;
    margin: 5px;
  }

  .poform-xsmall {
    width: 100px;
    text-align: center;
  }

  .poform-readonly {
    width: 100px;
  }

  .poform-large {
    width: 150px;
    text-align: center;
  }

  .poform-xlarge {
    width: 245px;
    text-align: center;
  }

  .poform-xxlarge{
    width: 375px;
    text-align: center;
  }

}